<template>
  <div>
    <v-row justify='center' align='center'>
      <v-col cols='4'>
        <v-autocomplete
          v-model='templateSelect'
          :items='templates'
          item-text='name'
          item-value='name'
          label='Layout'
          return-object
          placeholder='Selecione um layout'
          @change='forceRenderCanvas'
        />
      </v-col>
      <v-col v-if='templateSelect' cols='12'>
        <v-sheet>
          <v-slide-group
            class='pa-4'
            show-arrows
          >
            <v-slide-item
              v-for='cnhValue in selectedValues'
              :key='cnhValue.name'
              class='mx-2'
            >
              <Canvas
                v-if='renderCanvas'
                :id='cnhValue.name'
                :values='cnhValue'
                :template='templateSelect'
              />
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color='primary'
          class='mr-4'
          @click='backStep()'
        >
          {{$t('PREVIOUS_STEP')}}
        </v-btn>
        <v-btn
          color='secondary'
          class='mr-4'
          :disabled='selectedValues.length === 0 || !templateSelect'
          @click='showConfirmModal'
        >
          {{$t('NEXT')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col col='12'>
        <v-data-table
          :headers='headers'
          :items='selectedValues.filter((v) => !v.blank)'
          hide-default-footer
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';

  export default {
    name: 'TemplatePage',
    props: {
      selected: {
        type: Array,
        required: true,
      },
      templates: {
        type: Array,
        required: true,
      },
    },

    mounted: function () {
      this.geValues();
      this.getAllTemplate();
    },

    data: function () {
      return {
        dialog: false,
        renderCanvas: true,
        selectedValues: [],
        templateValues: [],
        headers: [
          { text: 'Nome', value: 'name' },
          { text: 'Data de Solicitação', value: 'requestDate' },
          { text: 'RENACH', value: 'renach' },
          { text: 'Documento Impresso', value: 'printed' },
          { text: 'Número espelho', value: 'mirrorNumber' },
        ],
        templateSelect: undefined,
      };
    },

    components: {
      Canvas: () => import('../components/Canvas/CNH/Canvas'),
    },

    methods: {
      geValues: function () {
        this.selectedValues = this.selected;
      },

      backStep: function () {
        this.$emit('back');
      },

      base64ToImage: function (img) {
        return `data:image/jpg;base64,${img}`;
      },

      forceRenderCanvas: function () {
        this.renderCanvas = false;

        this.$nextTick(() => {
          this.renderCanvas = true;
        });
      },

      showConfirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit('next');
            this.$emit('template', this.templateSelect);
          }
        });
      },
    },
  };
</script>
